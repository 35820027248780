import React, { Component } from "react";

const defaultState = {
  isMenuOpen: false,
  toggleMenu: () => {},
};

const MenuContext = React.createContext(defaultState);

class MenuProvider extends Component {
  state = {
    isMenuOpen: false,
  };

  toggleMenu = () => {
    console.log("toggleMenu");
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  componentDidMount() {}

  render() {
    const { children } = this.props;
    const { isMenuOpen } = this.state;
    return (
      <MenuContext.Provider value={{ isMenuOpen, toggleMenu: this.toggleMenu }}>
        {children}
      </MenuContext.Provider>
    );
  }
}

export default MenuContext;
export { MenuProvider };
